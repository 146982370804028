let slideIndex = 0;
const slides = document.querySelector('.slides');
const totalSlides = document.querySelectorAll('.slide').length;
let autoSlideInterval;
let startX = 0;
let isDragging = false;

function moveSlide(n) {
    slideIndex += n;
    if (slideIndex >= totalSlides) slideIndex = 0;
    if (slideIndex < 0) slideIndex = totalSlides - 1;
    updateSlidePosition();
    stopAutoSlide();
}

function updateSlidePosition() {
    slides.style.transform = `translateX(-${slideIndex * 100}%)`;
}

function startAutoSlide() {
    autoSlideInterval = setInterval(() => {
        moveSlide(1);
    }, 10000);
}

function stopAutoSlide() {
    clearInterval(autoSlideInterval);
}

function handleTouchStart(event) {
    stopAutoSlide();
    startX = event.touches ? event.touches[0].clientX : event.clientX;
    isDragging = true;
}

function handleTouchMove(event) {
    if (!isDragging) return;
    let currentX = event.touches ? event.touches[0].clientX : event.clientX;
    let diffX = startX - currentX;
    if (diffX > 50) {
        moveSlide(1);
        isDragging = false;
    } else if (diffX < -50) {
        moveSlide(-1);
        isDragging = false;
    }
}

function handleTouchEnd() {
    isDragging = false;
}

document.querySelector('.prev').addEventListener('click', () => {
    stopAutoSlide();
    moveSlide(-1);
});

document.querySelector('.next').addEventListener('click', () => {
    stopAutoSlide();
    moveSlide(1);
});

// Event listeners for touch and mouse events
slides.addEventListener('touchstart', handleTouchStart, { passive: true });
slides.addEventListener('touchmove', handleTouchMove, { passive: true });
slides.addEventListener('touchend', handleTouchEnd);

slides.addEventListener('mousedown', handleTouchStart);
slides.addEventListener('mousemove', handleTouchMove);
slides.addEventListener('mouseup', handleTouchEnd);
slides.addEventListener('mouseleave', handleTouchEnd);

// Start the auto slide when the page loads
startAutoSlide();
